import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';
import '../libs/tilt.jquery.min.js';


$(document).foundation();

var lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy"
});

const tiltSettings = {
	maxTilt: 2,
	scale: 1.035,
	speed: 500,
	easing: 'cubic-bezier(0.2, 0.5, 0.1, 1)'
};
// const tilt = $('.js-tilt').tilt(tiltSettings);

//--------------------------------------------------------------------------------
// Open navigation
var playingvideos = [];
var videoelements = [];
$('#menuToggle').click(function(e){

	if ($('body').hasClass('menu-open')) {
		if (playingvideos.length != 0) {
			for (var i=0; i<playingvideos.length; i++) {
				var video = playingvideos[i];
				var isPlaying = video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2;
				if (!isPlaying) {
				  video.play();
				}
			}
			playingvideos = [];
			videoelements = [];
		}
	} else {
		videoelements = $('body').find('video');
		if (videoelements.length != 0) {
			for (var i=0; i<videoelements.length; i++) {
			var video = videoelements[i];
			var isPlaying = video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2;
			if (isPlaying) {
			  video.pause();
			  playingvideos.push(videoelements[i]);
			}
		}
		}
	}

	$(this).toggleClass('active');
	$('.nav-layover').toggleClass('active');
	$('#hero-logo-container').toggleClass('hidden');
	$('body').toggleClass('menu-open');
});


//--------------------------------------------------------------------------------
// Mouse hero video darker

// $('#hero-clickable').mousemove(function(e){
//   var offs = $(this).offset(),
//       p    = {x:offs.left, y:offs.top},
//       mPos = {x:e.pageX, y:e.pageY},
//       x    = mPos.x - p.x - 50,
//       y    = mPos.y - p.y - 50;
      
//   $('.video-overlay-dark', this).css({backgroundPosition: -x+'px '+-y+'px'}); 
// });

//--------------------------------------------------------------------------------
// Pins croll
var scrollController = new ScrollMagic.Controller();




//--------------------------------------------------------------------------------

// new ScrollMagic.Scene({
// 		triggerElement: '.work-panel',
// 		// duration: 110,    // the scene should last for a scroll distance of 100px
//     offset: -100    // start this scene after scrolling for 50px
// 	})
// 	.on('start', function (e) {
// 		.setClassToggle(e, 'work-panel--active')
// 	})
// 	.addTo(scrollController);


// var bgslides = $(".changing-bg");
// 	for (var i=0; i<bgslides.length; i++) {
// 		new ScrollMagic.Scene({
// 				triggerElement: bgslides[i],
// 				triggerHook: .7,
// 				duration: "120%"
// 				// offset: 300 
// 			})
// 			.on("enter", function (e) {
// 				$('body').addClass('bg-color--' + $(this.triggerElement()).attr('data-bgname'));

// 				var videoel = $(this.triggerElement()).find('video');
				
// 				if (videoel.length != 0) {
// 					var video = videoel.get(0);

// 					var isPlaying = video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2;
// 					if (!isPlaying) {
// 					  video.play();
// 					}
// 				}
// 			})
// 			.on("leave", function (e) {
// 				$('body').removeClass('bg-color--' + $(this.triggerElement()).attr('data-bgname'));

// 				var videoel = $(this.triggerElement()).find('video');
				
// 				if (videoel.length != 0) {
// 					var video = videoel.get(0);

// 					var isPlaying = video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2;
// 					if (isPlaying) {
// 					  video.pause();
// 					}
// 				}
// 			})
// 			.addIndicators()
// 			// .set(bgslides[i], {left: "-=100"});
// 			// .setPin(bgslides[i])
// 			// .setClassToggle('body', 'bg-color--' + $(bgslides[i]).attr('data-bgname'))
// 			// .addTo(scrollController);
// 	}

// Fadeins
var makeFadeInSlide = function(fadeinslide) {
	new ScrollMagic.Scene({
			triggerElement: fadeinslide,
			triggerHook: 0.94,
			duration: "160%",
			// offset: 0 
		})
		.setClassToggle(fadeinslide, 'js-anim-fadein--active')
		.on("enter", function (e) {

			var videoel = $(this.triggerElement()).find('video');
			
			if (videoel.length != 0) {
				var video = videoel.get(0);

				var isPlaying = video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2;
				if (!isPlaying) {
				  video.play();
				}
			}
		})
		.on("leave", function (e) {

			var videoel = $(this.triggerElement()).find('video');
			
			if (videoel.length != 0) {
				var video = videoel.get(0);

				var isPlaying = video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2;
				if (isPlaying) {
				  video.pause();
				}
			}
		})
		.addTo(scrollController)
		;
}
var fadeinslides = $(".js-anim-fadein");
	for (var i=0; i<fadeinslides.length; i++) {
		makeFadeInSlide(fadeinslides[i]);
	}




// WORK TITLE
// var worktitle = $(".work-title-container");
// 	for (var i=0; i<worktitle.length; i++) {
// 		new ScrollMagic.Scene({
// 				triggerElement: worktitle[i],
// 				triggerHook: 0.7,
// 				// duration: 400,
// 				// offset: 300
// 			})
// 			.addIndicators()
// 			.setClassToggle(worktitle[i], 'js-anim-fadein--active')
// 			// .addTo(scrollController)
// 			;
// 	}
// var worktitle = $(".work-title-container");
// 	for (var i=0; i<worktitle.length; i++) {
// 		new ScrollMagic.Scene({
// 				triggerElement: worktitle[i],
// 				triggerHook: 0.7,
// 				duration: 600,
// 				offset: 500
// 			})
// 			.addIndicators()
// 			.setPin(worktitle[i])
// 			// .addTo(scrollController)
// 			;
// 	}


// WORK PREVIEW
// var workpreviews = $(".work-preview-container");
// 	for (var i=0; i<workpreviews.length; i++) {
// 		new ScrollMagic.Scene({
// 				triggerElement: workpreviews[i],
// 				triggerHook: 0.8,
// 				// duration: 100,
// 				// offset: 500
// 			})
// 			.addIndicators()
// 			// .setClassToggle(workpreviews[i], 'js-anim-fadein--active')
// 			// .addTo(scrollController)
// 			;
// 	}
	// var workpreviews = $(".work-preview-container");
	// for (var i=0; i<workpreviews.length; i++) {
	// 	new ScrollMagic.Scene({
	// 			triggerElement: workpreviews[i],
	// 			// triggerHook: 0.8,
	// 			// duration: 400,
	// 			// offset: 500
	// 		})
	// 		// .addIndicators()
	// 		// .setPin(workpreviews[i])
	// 		.addTo(scrollController)
	// 		;
	// }


// var workpanels = [];
// var workpanelslides = $('.work-preview');
// for (var i=0; i<workpanelslides.length; i++) {
// 	workpanels.push($(this).attr('data-openwork'));
// }

var loaderSpinner = '<div class="loader">Loading</div>';

var workfadeinslides = [];
var openedWorkScrollY = 0;
var openCaseStudy = function(openPanel) {
	var openWork = $('.work-panel--'+openPanel);
	var title = openPanel;
	var workDetails = openWork.find('.work-details-container');
	var currentPath = window.location.href.split(/[?#]/)[0];
	var contentPath = currentPath+'assets/data/work-'+openPanel+'.html';
	// var openWorkTilt = openWork.find('.js-tilt');
	
	if (openWork.hasClass('work-panel--opened')) {
		// already opened, do nothing, just scroll down to content
		$('html, body').animate({
			scrollTop: ($(window).width() * 0.56)+'px'
		}, 800);
	} else {
		// open work
		// openWorkTilt.tilt.destroy.call(openWorkTilt);

		openedWorkScrollY = openWork.offset().top;
		$('html, body').animate({
			scrollTop: openedWorkScrollY
		}, 500, function() {
			$('body').addClass('work-opened');
			$('body').addClass('bg-color--'+openPanel);
			$(workDetails).html(loaderSpinner);
			$('.work-panel').hide();
			openWork.show();
			openWork.addClass('work-panel--opened');

			$(workDetails).load(contentPath, function( response, status, xhr ) {
			  if ( status == 'error' ) {
			  	console.log('Error loading content');
			  	$(workDetails).html('<p class="text-center mt-3 mb-3">Coming soon</p>');
			  } else {
			  	console.log('Loaded work content');

			  	workfadeinslides = $(".js-anim-fadein");
					for (var i=0; i<workfadeinslides.length; i++) {
						makeFadeInSlide(workfadeinslides[i]);
					}
					lazyLoadInstance.update();
			  }
			});
			$('html, body').animate({
				scrollTop: 0
			}, 0);
		});
		window.history.pushState({}, title, currentPath+'#'+title);
	}
}

$('.work-preview').click(function(e){
	var openPanel = $(this).attr('data-openwork');
	openCaseStudy(openPanel);
});

$('.mobile-casestudylink').click(function(e){
	var openPanel = $(this).attr('data-openwork');
	openCaseStudy(openPanel);
});

var goBack = function() {
	$('body').removeClass('work-opened');
	$('body').removeClass(function (index, className) {
    return (className.match (/(^|\s)bg-color--\S+/g) || []).join(' ');
	});
	$('.work-panel').show();
	// tilt.tilt(tiltSettings);
}
var closeAllOpenWork = function() {
	var scrollToTopFirst = function() {
		$('html, body').animate({
				scrollTop: 0
			}, 'slow', function() {
				goBack();
			});
	}
	if (window.scrollY > 0) {
		scrollToTopFirst();
	} else {
		goBack();
	}
	$('html, body').animate({
		scrollTop: openedWorkScrollY
	}, 0, function(){
		$('.work-panel').removeClass('work-panel--opened');
		$('html, body').animate({
			scrollTop: '+=-30px'
		}, 300);
	});
	openedWorkScrollY = 0;
}
$('#backMenu').click(function(e){
	closeAllOpenWork();
	var title = 'Saturist';
	window.history.pushState({}, title, window.location.href.split(/[?#]/)[0]);
});

if(window.location.hash) {
	console.log('Found work, loading');
  var hash = window.location.hash.substring(1);
  openCaseStudy(hash);
} else {
  console.log('No work to load');
}
window.addEventListener('popstate', function(event) {
  console.log('Back to homepage');
  if(window.location.hash) {
  	console.log('Found work, loading');
	  var hash = window.location.hash.substring(1);
	  openCaseStudy(hash);
  } else {
  	closeAllOpenWork();
  }
}, false);


// var workpanel = $(".work-panel");
// 	for (var i=0; i<workpanel.length; i++) {
// 		new ScrollMagic.Scene({
// 				triggerElement: workpanel[i],
// 				// triggerHook: .05,
// 				// duration: 200,
// 				// offset: 0 
// 			})
// 			// .addIndicators()
// 			// .setPin(workpanel[i])
// 			.setClassToggle(workpanel[i], 'work-panel--active')
// 			// .setTween($(workpanelslides[i]).find('.work-description-container'), {opacity: 1})
// 			.addTo(scrollController)
// 			;
// 	}

// 	// load quotes when scrolling
// var workdescriptiontexts = $(".work-description-container");
// 	for (var i=0; i<workdescriptiontexts.length; i++) {
// 		new ScrollMagic.Scene({
// 				triggerElement: workdescriptiontexts[i],
// 				triggerHook: .6,
// 				duration: 200,
// 				offset: 400 
// 			})
// 			.setTween(workdescriptiontexts[i], {opacity: 1})
// 			// .setClassToggle(workdescriptiontexts[i], 'js-anim-fadein--active')
// 			.addTo(scrollController)
// 			;
// 	}


// Neurisium
// var neurisiumScene1 = new ScrollMagic.Scene({triggerElement: "#neurisium-hex-area", duration: "100%", triggerHook: .5})
// 	// animate color and top border in relation to scroll position
// 	.setTween(".neurisium-bg-video", {top: '55%'})
// 	.addTo(scrollController);

// var neurisiumScene2 = new ScrollMagic.Scene({triggerElement: "#neurisium-hex-area", duration: "100%", triggerHook: .5})
// 	// animate color and top border in relation to scroll position
// 	.setTween(".hero-video-reverse", {top: '10%'})
// 	.addTo(scrollController);

// // move extra work details when scrolling
// var extraTweenTl = {};
// var createExtraTweenTl = function(extratween1, extratween2, extratween3) {
// 	extraTweenTl = new TimelineMax();
// 	var tlExtraTween1 = TweenMax.to(extratween1, 1, {
// 			left: '10%',
// 			bottom: '-10%',
// 			opacity: "1",
// 		})
// 	var tlExtraTween2 = TweenMax.to(extratween2, 1, {
// 			left: '50%',
// 			bottom: '-10%',
// 			opacity: "1",
// 		})
// 	var tlExtraTween3 = TweenMax.to(extratween3, 1, {
// 			left: '90%',
// 			bottom: '-10%',
// 			opacity: "1",
// 		})

// 	extraTweenTl.add(tlExtraTween1).add(tlExtraTween2).add(tlExtraTween3);

// 	return extraTweenTl;
// }
// var extraslides = $(".work-preview-container");
// 	for (var i=0; i<extraslides.length; i++) {
// 		new ScrollMagic.Scene({
// 				triggerElement: extraslides[i],
// 				duration: 200,
// 				offset: 400 
// 			})
// 			.setTween(createExtraTweenTl(extraslides[i].children[0], extraslides[i].children[1], extraslides[i].children[2]))
// 			.addTo(scrollController);
// 	}



//--------------------------------------------------------------------------------


// var herotween  = TweenMax.to("#hero-clickable", 1, {
// 		// height: '50vh',
// 	}
// );
// var totalscene = new ScrollMagic.Scene({
//       // duration: "50%",    // the scene should last for a scroll distance of 100px
//       // offset: 0    // start this scene after scrolling for 50px
//   })
	
	// .on("end", function (e) {
	//   if (e.scrollDirection == "FORWARD" ) { // check pin progress so it doesnt launch on refresh
	//     // totalscene.setPin("#index-page");
	//   // } else {
	//   } else {
	//   	totalscene.removePin("#index-page");
	//   }
	// })
	// .reverse(false)
	// .setTween(herotween)
	// .setPin("#index-page")

  // .setPin("#index-page") // pins the element for the the scene's duration
  // .addTo(scrollController); // assign the scene to the controller

$('#hero-clickable').click(function(){
	$('html, body').animate({
		scrollTop: ($('#hero-clickable').height() * 1)+'px'
		// scrollTop: '400px'
	}, 800)
});
// $('.js-scrollToTop').click(function(){
// 	$('html, body').animate({
// 		scrollTop: 0+'px'
// 	}, 800)
// });

// // TweenMax.set("#animate2", {left: "-=100"});
// $('.svg-monument').css({'transform': 'translateZ(1600px) translateX(-50%) rotateZ(0deg) rotateY(180deg) rotateX(90deg) translateY(-200%);', 'top': '50%;' });

// var tween1 = TweenMax.to(".youllreadthis", 1, {
// 			scale: 0.7,
// 		}
// 	);
// var tween2 = TweenMax.to("#hero-header", 1, {
// 			transform: "translateY(-45px)",
// 			color: 'white'
// 		}
// 	);
// var tween3 = TweenMax.to(".youllreadthis", 1, {
// 			opacity: 0
// 		}
// 	);
// var tween4 = new TimelineMax()
// 	.to(".svg-monument", 1, {
// 		top: '50%',
// 		transform: "translateZ(1600px) translateX(-50%) rotateZ(0deg) rotateY(180deg) rotateX(90deg) translateY(-100%)",
// 		}
// 	)
// 	.to(".svg-monument", 1, {
// 		transform: "translateZ(1600px) translateX(-50%) rotateZ(0deg) rotateY(180deg) rotateX(90deg) translateY(30%)",
// 		}
// 	)
// 	.to(".svg-monument", 1, {
// 		transform: "translateZ(1600px) translateX(-50%) rotateZ(0deg) rotateY(180deg) rotateX(90deg) translateY(30%)",
// 		}
// 	)
// 	.to(".svg-monument", 1, {
// 		transform: "translateZ(-2600px) translateX(-50%) rotateZ(0deg) rotateY(-120deg) rotateX(0deg) translateY(0%)",
// 		}
// 	)
// 	.to(".svg-monument", 1, {
// 		transform: "translateZ(-9600px) translateX(-50%) rotateZ(0deg) rotateY(-90deg) rotateX(0deg) translateY(-50%)",
// 		}
// 	)
// ;
// var tweentwo = new TimelineMax()
// 	.to("#hero-header", 1, {
// 			transform: "translateY(-100px)",
// 			opacity: 0
// 		}
// 	)
// 	.to("#hero-footer", 0.5, {
// 			opacity: 0,
// 		}
// 	)
// 	.to("youllreadthis", 1, {
// 		opacity: 0,
// 		}
// 	)
// 	.to("#index-hero", 0.2, {
// 		className: "+=index-hero--active",
// 		}
// 	)
// 	// .to(".svg-scene", 1, {
// 	// 	className: "+=svg-scene--active",
// 	// 	}
// 	// )
// 	// .to(".svg-monument", 1, {
// 	// 	transform: "translateZ(1600px) translateX(-50%) rotateZ(0deg) rotateY(180deg) rotateX(90deg) translateY(30%)",
// 	// 	}
// 	// )
// 	// .to(".svg-monument", 1, {
// 	// 	transform: "translateZ(-2600px) translateX(-50%) rotateZ(0deg) rotateY(-120deg) rotateX(0deg) translateY(0%)",
// 	// 	}
// 	// )
// 	// .to(".svg-monument", 1, {
// 	// 	transform: "translateZ(-9600px) translateX(-50%) rotateZ(0deg) rotateY(-90deg) rotateX(0deg) translateY(-50%)",
// 	// 	}
// 	// )
// 	.to(".svg-scene", 1, {
// 		top: "-20%",
// 		}
// 	)
// 	.to("#hero-main", 1, {
// 		opacity: 1,
// 		}
// 	)
// ;
// var tween4 = TweenMax.to("#index-hero", 1, {className: "+=index-hero--active"});

// var timeline = new TimelineMax();

// timeline.add(tween1).add(tween4);

// var scene1 = new ScrollMagic.Scene({
//       duration: 100,
//       offset: 0
//   })
// 	// .setTween('.svg-monument', {transform, "translateZ(1600px) translateX(-50%) rotateZ(0deg) rotateY(180deg) rotateX(90deg) translateY(30%)"})
// 	.setTween(timeline)
//   .addTo(scrollController); 

// var scene2 = new ScrollMagic.Scene({
//       duration: 500,
//       offset: 0
//   })
// 	.setTween(tween2)
//   .addTo(scrollController); 

// var scene3 = new ScrollMagic.Scene({
//       duration: 100,
//       offset: 0,
//   })
// 	.setTween(tween4)
//   .addTo(scrollController); 


//--------------------------------------------------------------------------------
// custom scroll 

// var heroheader = document.getElementById('hero-header');
// var herofooter = document.getElementById('hero-footer');
// var youllreadthis = document.getElementById('youllreadthis');
// var youllreadthismain = document.getElementById('youllreadthis-main');
// var youllreadthistext = document.getElementById('hero-secondary-text');
// var indexhero = $('#index-hero');

// // youllreadthis.style.opacity = 1;

// // $('.svg-monument').hide();
// // $('#hero-main').hide();
// // $('.svg-monument').hide();
// // $('.youllreadthis').css({'opacity': 1, 'transform': 'scale(1)'});
// $('.youllreadthis').show();

// var locked = false;
// var runOnScroll =  function(evt) {

//   // console.log(window.pageYOffset);

//   if(locked) return;
//   locked = true; // keeps rerunning the function while it's running

//   if(window.pageYOffset < 100) {

//   	if(indexhero.hasClass('index-hero--active')) {

//   	} else {
// 	    heroheader.style.top = -(window.pageYOffset) + 'px';
// 	    youllreadthismain.style.opacity = 1-(window.pageYOffset / 100);
// 	    youllreadthistext.style.opacity = 1-(window.pageYOffset / 120);
// 	    herofooter.style.opacity = 1-(window.pageYOffset / 100);
// 	    // $('.youllreadthis').css('transform: scale('+ (1 -(window.pageYOffset / 100)) +')');
// 	    youllreadthismain.style.transform = 'scale('+ (1 - ((window.pageYOffset / 100) * 0.3)) +')';
//     }
//     // console.log('asdad ' + (0 - ((window.pageYOffset / 100) * 200)) );
//     // var tweenAmount = window.pageYOffset / 300;
//     // TweenMax.to(mat.uniforms.dispFactor, speedIn, {
//     //       value: tweenAmount,
//     //       ease: easing
//     // });
//   } else {
//     // TweenMax.to(mat.uniforms.dispFactor, speedIn, {
//     //       value: 1,
//     //       ease: easing
//     // });
//   }
//   if(window.pageYOffset > 100) {
//   	// $('.svg-monument').show();
//   	// $('#hero-main').show();
//   	indexhero.addClass('index-hero--active');
//   	// $('.svg-monument').css({
//    //  	'opacity': 1, 
//    //  	'transform': 'translateZ(1600px) translateX(-50%) rotateZ(0deg) rotateY(180deg) rotateX(90deg) translateY(-15%)',  //'+ -(20 - ((window.pageYOffset / 100) * 20)) +'
//    //  	'top': '40%', // (50-((window.pageYOffset / 100) * 50)) +'%'
//    //  });
//   	// youllreadthis.style.opacity = (window.pageYOffset / 100);
//   	// $('.svg-monument').css({'opacity': 1, 'transform': 'translateZ(1600px) translateX(-50%) rotateZ(0deg) rotateY(180deg) rotateX(90deg) translateY('+ (0 - ((window.pageYOffset / 100) * 10)) +'%);', 'top': (((window.pageYOffset / 100) * 50)) +'%;' });
//   }

//   if(window.pageYOffset > 100) {
//   	// $('.youllreadthis').hide();
//   	// $('#hero-main').css({
//    //  	'opacity': 1
//   	// }); 
// 	  // youllreadthis.addClass('youllreadthis--active');
// 	  // youllreadthis.style.opacity = 0;
// 	} else {
// 		// indexhero.removeClass('index-hero--active');
// 		// youllreadthis.style.transform = 'scale(1)';
// 		// youllreadthis.style.opacity = 1;
// 	  // youllreadthis.removeClass('youllreadthis--active');
// 	}
//   locked = false;
// };

// window.addEventListener("scroll", runOnScroll);


//--------------------------------------------------------------------------------
// $(window).scroll(function() {
  
//   // selectors
//   var $window = $(window),
//       $body = $('body'),
//       $panel = $('.changing-bg');
  
//   // Change 33% earlier than scroll position so colour is there when you arrive.
//   var scroll = $window.scrollTop() + ($window.height() / 3);
 
//   $panel.each(function () {
//     var $this = $(this);
    
//     // if position is within range of this panel.
//     // So position of (position of top of div <= scroll position) && (position of bottom of div > scroll position).
//     // Remember we set the scroll to 33% earlier in scroll var.
//     if ($this.position().top <= scroll && $this.position().top + $this.height() > scroll) {
          
//       // Remove all classes on body with color-
//       $body.removeClass(function (index, css) {
//         return (css.match (/(^|\s)color-\S+/g) || []).join(' ');
//       });
//        console.log('asd');
//       // Add class of currently active div
//       $body.addClass('color-' + $(this).data('color'));
//     }
//   });    
  
// }).scroll();

//--------------------------------------------------------------------------------


// var light = new Photon.Light(13000, 8000, 3000);
// var scene = $('.svg-monument');

// // var face = new Photon.Face( $('.face')[2] );

// var obelisk = $('.svg-monument');
// var obeliskItems = obelisk.find('.face');
// var obeliskFaces = [];

// obeliskItems.each(function(i) {
// 	obeliskFaces[i] = new Photon.Face($(this)[0], 0.9, 0.1, true);
// });

// // var obelisk = new Photon.FaceGroup( scene, faces, .5, 0.1, true );
// obeliskFaces[i].render(light, true);

// face.render(light, true);

